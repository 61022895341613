.zhibiao-detail-container {
    min-height: 100vh;
    padding: 24px 20px;
    box-sizing: border-box;
}

.zhibiao-header {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.zhibiao-title {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 0 20px 0;
    letter-spacing: 0.5px;
}

.zhibiao-current-value {
    margin: 24px 0;
}

.zhibiao-current-value .label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 8px;
}

.value-container {
    display: flex;
    align-items: baseline;
    gap: 8px;
}

.value-container .value {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 1;
}

.value-container .unit {
    font-size: 12px;
    color: #fff;
    background: rgba(94, 175, 255, 0.15);
    padding: 4px 8px;
    border-radius: 4px;
    backdrop-filter: blur(4px);
}

.zhibiao-stats {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;
}

.qoq-value {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.qoq-value .label {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.qoq-value.positive .value {
    color: #ff5e5e;
}

.qoq-value.negative .value {
    color: #b5ffb5;
}

.update-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    text-align: right;
    line-height: 1.6;
}

.zhibiao-chart {
    margin: 24px 0;
    padding: 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    backdrop-filter: blur(10px);
}

.zhibiao-detail-list {
    margin-top: 24px;
}

.list-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
}

.list-header h2 {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
}

.unit-tag {
    font-size: 12px;
    color: #fff;
    background: rgba(94, 175, 255, 0.15);
    padding: 4px 8px;
    border-radius: 4px;
    backdrop-filter: blur(4px);
}

.list-content {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    overflow: hidden;
}

.list-content .list-row {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
}

.list-content .list-row:last-child {
    border-bottom: none;
}

.list-content .value-col {
    font-size: 14pt;
    color: #eee;
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.date-col {
    font-size: 12pt;
    color: #aaa;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;
    white-space: nowrap;
}

@media screen and (max-width: 360px) {
    .list-content .value-col {
        font-size: 12pt;
    }
    
    .list-content .date-col {
        font-size: 11pt;
    }
}

.zhibiao-footer {
    margin-top: 24px;
    padding: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
}

.table-container {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    overflow: hidden;
    margin-top: 16px;
    backdrop-filter: blur(10px);
    padding: 1px;
}

.custom-table {
    width: 100%;
}

/* 移动设备适配 */
@media screen and (max-width: 360px) {
    .table-container {
        margin-top: 12px;
    }
} 