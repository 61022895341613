.vname{
    font-size: 18pt;
    color: white;
}
.vtitle{
    font-size: 12pt;
    color: #cccccc;
}
.vclose{
    font-size: 30pt;
    font-weight: bold;
    color: white;
}