/* src/Tutorial.css */

.tutorial-container {
    padding: 40px;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tutorial-title {
    margin-bottom: 40px;
    color: #fff;
    font-family: 'Arial, sans-serif';
}


.tutorial-desc {
    color: #fff;
    font-size: 14px;
    font-family: 'Arial, sans-serif';
}

.tutorial-button-container {
    text-align: center;
    margin-top: 60px;
}