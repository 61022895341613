.app-icon-container {
    display: flex;
    justify-content: center;
}

.title{
    display: flex;
    justify-content: center;
}
.app-screenshots {
    display: flex;
    overflow-x: scroll;
    justify-content: center;
    margin-top: 10px;

}

.app-screenshot-container {
    flex: 0 0 auto;
    margin-right: 2px;
}

.app-screenshot {
    width: 80%;
    max-width: 300pt;
}
