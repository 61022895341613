.glass-container {
    position: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.glass-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
}

.content-container {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 8vw 0;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
}

.song-info {
    text-align: center;
    color: white;
}

.song-title {
    font-size: 16pt;
    margin-bottom: 4vw;
}

.song-artist {
    font-size: 10pt;
}

.player-container {
    width: 100%;
    height: 80vw;
    max-height: 480px;
    margin-top: 20pt;
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20vw;
}

.play-button {
    z-index: 3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20vw;
    height: 20vw;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cover-container {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 40vw;
    max-width: 240px;
    max-height: 240px;
    border-radius: 50%;
    overflow: hidden;
}

.cover-container img {
    width: 100%;
    height: 100%;
    background: #fff;
    object-fit: cover;
}

.player-background {
    width: 80vw;
    height: 80vw;
    max-width: 480px;
    max-height: 480px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.comment-container {
    width: 100%;
    margin-top: auto;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.comment-content {
    color: white;
    font-size: 14pt;
    background-color: rgba(24, 24, 24, 0.533);
    border-radius: 20px;
    min-height: 80px;
    max-height: 160px;
    width: 100%;
    padding: 20px;
    position: relative;
    overflow-y: auto;
}

.comment-author {
    color: grey;
    font-size: 10pt;
    position: absolute;
    right: 5vw;
    bottom: 4vw;
    background-color: rgba(24, 24, 24, 0.8);
    padding: 2vw;
    border-radius: 2vw;
}

.rotating {
    animation: rotate 10s linear infinite;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: white;
    background-color: #000;
}

.progress-container {
    width: 80%;
    margin: 20px auto;
    position: relative;
    z-index: 4;
    display: flex;
    gap: 15px;
    align-items: center;
    max-width: 500px;
}

.progress-content {
    flex: 1;
}

.time-info {
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    margin-bottom: 8px;
}

.controls-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.next-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    font-size: 20px;
}

.next-button:hover {
    color: #fff;
    transform: scale(1.1);
}

.next-button:active {
    transform: scale(0.95);
}

.progress-bar {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

.progress-current {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 1000;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.2s ease;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    border-top-color: rgba(255, 255, 255, 0.6);
    animation: spin 0.8s linear infinite;
}

.loading-text {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 1px;
    animation: pulse 2s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
