

#kind-page-sidebar::-webkit-scrollbar {
    display: none;
}


@font-face {
    font-family: 'sa-digital';
    src: url("font/sa-digital-number.ttf");
    font-weight: normal;

}

textarea {
    -webkit-user-select: text!important;
    contenteditable:true
}

body{
    background: #000000;
    color:white;
}

.r_page{
    padding-top: 10vw;
    padding-left: 5vw;
    padding-right: 5vw;
    width: 90vw;
    overflow-x: clip;
}

.r_title {
    font-size: 20pt;
    font-weight: bold;
}


.r_title2 {
    font-size: 18pt;
    font-weight: bold;
    letter-spacing: 1.12pt
}
.r_subTitle {
    font-size: 16pt;
    font-weight: bold;
    letter-spacing: 1.12pt
}
.r_content {
    font-size: 12pt;
    letter-spacing: 1pt
}
.r_bg{
    background-color: #181818;
    border-radius: 20pt;

}

.r_gray{
    color: #D7D7D7;
    font-size: 10pt;
}

.r_blue{
    color: #808D9E;
    font-size: 8pt;
}
.r_gray2{
    color: #808D9E;
    font-size: 8pt;
}
.r_main_count{
    color: #ffffff;
    font-size: 23pt;
    letter-spacing: 0;
    font-family: 'sa-digital', serif;
}
.r_sub_count{
    color: #ffffff;
    font-size: 14pt;
    letter-spacing: 0;
    font-family: 'sa-digital', serif;
}
.r_card{
    border-radius: 10pt;
    width: 90vw;
    display: flex;


}