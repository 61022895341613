.intro-container, .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.intro-container h1 {
    font-size: 24px;
    margin-bottom: 8px;
}

.intro-container p, .auth-container p {
    font-size: 18px;
    margin: 16px 0;
}

.computer-icon {
    width: 240px;
    height: 240px;
    margin-bottom: 16px;
}
.icon {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
}

.mainBtn {
    width: 360px;
    height: 50px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    background-color: #007bff;
    color: #fff;
}
